// extracted by mini-css-extract-plugin
export var root = "header-module--root--KGdif";
export var wrapper = "header-module--wrapper--uoQUP";
export var mobileBranding = "header-module--mobileBranding--Odqvl";
export var branding = "header-module--branding--i2gkj";
export var homeBranding = "header-module--homeBranding--xp5eD";
export var homeBrandingContainer = "header-module--homeBrandingContainer--N92+C";
export var homeBrandingContainerScrolling = "header-module--homeBrandingContainerScrolling--nXWMj";
export var toggleNavButton = "header-module--toggleNavButton--r2rxY";
export var nav = "header-module--nav--e8OJ3";
export var active = "header-module--active--a9N7H";
export var showNav = "header-module--showNav--Q9H09";
export var close = "header-module--close--85H9G";
export var link = "header-module--link--muri5 typography-module--link--GMdJW";
export var mobileNav = "header-module--mobileNav--AQifc";
export var mobileNavContainer = "header-module--mobileNavContainer--Lhw5m";
export var mobileNavFooter = "header-module--mobileNavFooter--kQtYM";
export var mobileNavShown = "header-module--mobileNavShown--q-93z";
export var mobileHeader = "header-module--mobileHeader--WHE07";