import React from "react";

const HamburgerIcon = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<line y1="2" x2="20" y2="2" stroke="black" stroke-width="1.5"/>
<line y1="10" x2="20" y2="10" stroke="black" stroke-width="1.5"/>
<line y1="18" x2="20" y2="18" stroke="black" stroke-width="1.5"/>
</svg>


);

export default HamburgerIcon;
