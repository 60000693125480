import React from 'react'

const LogoIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 627 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.38 30.04C41.82 28.59 46.26 23.89 46.26 17.39C46.26 16.85 46.23 16.31 46.17 15.77C45.72 11.73 43.51 8.10003 40.18 5.78003C35.91 2.80003 30.72 2.85003 25.7 2.85003H16.29H2.15002V3.28003L6.68002 7.81003V57.65L2.15002 62.27V62.7H28.23C42.17 62.7 50.37 56.54 50.37 46.03C50.37 37.13 44.3 31.06 34.38 30.04ZM17.02 5.42003H19.16C30.02 5.42003 36.52 10.46 36.52 18.59C36.52 25.17 32.24 29.36 25.15 29.36H17.03V5.42003H17.02ZM24.29 59.45H17.02V32.52H22.58C33.95 32.52 39.68 36.96 39.68 45.86C39.68 54.49 34.12 59.45 24.29 59.45Z" fill="black"/>
<path d="M132.54 50.04L119.21 59.45H105.18V7.80998L109.63 3.27998V2.84998H90.31V3.27998L94.84 7.80998V57.65L90.31 62.27V62.7H132.97V50.04H132.54Z" fill="black"/>
<path d="M170.27 59.4199H156.25V7.77995L160.69 3.24995V2.81995H141.37V3.24995L145.9 7.77995V57.6199L141.37 62.2399V62.6699H184.03V50.0099H183.6L170.27 59.4199Z" fill="black"/>
<path d="M58.81 2.84998V3.27998L63.34 7.89998V57.65L58.81 62.27V62.7H78.02V62.27L73.48 57.65V7.89998L78.02 3.27998V2.84998H58.81Z" fill="black"/>
<path d="M216.41 3.27998L221.88 9.00998L208.72 30.04L194.87 7.89998L199.05 3.27998V2.84998H180.65H180.04V3.27998L184.63 7.89998L200.75 33.54V57.65L196.22 62.27V62.7H215.54V62.27L211.01 57.65V33.54L226.93 8.48998L231.12 3.27998V2.84998H216.41V3.27998Z" fill="black"/>
<path d="M354.23 1.55005C338.25 1.55005 325.08 13.6 325.08 33.1C325.08 51.48 336.62 63.9701 352.95 63.9701C369.02 63.9701 382.1 51.91 382.1 32.42C382.11 13.95 370.65 1.55005 354.23 1.55005ZM354.58 60.3801C348.85 60.3801 344.32 57.82 340.99 52.69C337.74 47.47 336.12 40.38 336.12 31.23C336.12 14.22 342.1 5.15005 352.62 5.15005C358.35 5.15005 362.88 7.71005 366.13 12.93C369.46 18.06 371.09 25.16 371.09 34.3C371.08 51.31 365.09 60.3801 354.58 60.3801Z" fill="black"/>
<path d="M386.49 14.04H386.92L399.31 6.16997H408.35V57.64L403.91 62.26V62.68H423.23V62.26L418.7 57.64V6.16997H428.33L440.13 14.04H440.56V2.83997H386.49V14.04Z" fill="black"/>
<path d="M536.34 1.55005C520.35 1.55005 507.19 13.6 507.19 33.1C507.19 51.48 518.73 63.9701 535.06 63.9701C551.13 63.9701 564.21 51.91 564.21 32.42C564.22 13.95 552.76 1.55005 536.34 1.55005ZM536.69 60.3801C530.96 60.3801 526.43 57.82 523.1 52.69C519.85 47.47 518.23 40.38 518.23 31.23C518.23 14.22 524.21 5.15005 534.73 5.15005C540.46 5.15005 544.99 7.71005 548.24 12.93C551.57 18.06 553.2 25.16 553.2 34.3C553.19 51.31 547.2 60.3801 536.69 60.3801Z" fill="black"/>
<path d="M449.5 14.04H449.93L462.33 6.16997H471.36V57.64L466.92 62.26V62.68H486.24V62.26L481.71 57.64V6.16997H491.34L503.14 14.04H503.57V2.83997H449.5V14.04Z" fill="black"/>
<path d="M618.39 2.83997H616.76H608.79V3.25997L615.43 9.98997V44.34L615.36 44.05L585.61 2.83997H584.56H578.7H577.07H575.93H571.21V3.25997L575.74 7.87997V55.53L571.21 62.26V62.68H577.07H578.7H586.67V62.26L580.03 55.53L580.13 12.75L616.3 62.68H619.72V9.98997L624.25 3.25997V2.83997H618.39Z" fill="black"/>
<path d="M303.85 57.1301C291.2 57.1301 282.13 45.8401 282.13 30.1101C282.13 15.6601 290 5.06006 300.43 5.06006C301.71 5.06006 302.91 5.06006 303.51 5.32006L316.85 15.7501H317.28V4.55006C313.52 2.58006 308.56 1.56006 303.17 1.56006C284.45 1.56006 271.62 14.9001 271.62 33.9601C271.62 51.2301 283.08 63.9701 298.81 63.9701C309.75 63.9701 318.39 57.6401 319.93 48.2401L319.59 48.0701C316.5 53.7901 310.77 57.1301 303.85 57.1301Z" fill="black"/>
</svg>

)

export default LogoIcon