import React from "react";

const strokeStyle = { vectorEffect: "non-scaling-stroke" };

const CloseIcon = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="0.464393" y1="0.479454" x2="19.6313" y2="19.6464" stroke="black" stroke-width="1.5"/>
<line x1="19.6314" y1="0.353553" x2="0.464429" y2="19.5205" stroke="black" stroke-width="1.5" />
</svg>


);

export default CloseIcon;
