import React from "react";
import Header from "./header";
import Footer from "./footer";

import "../styles/layout.css";
import * as styles from "./layout.module.css";

const Layout = ({
  children,
  homeIntroPlayed,
  setHomeIntroPlayed,
  onHideNav,
  onShowNav,
  showNav,
  location,
  siteTitle,
}) => { 
  
  return(
  <>
    <Header
      homeIntroPlayed={homeIntroPlayed}
      setHomeIntroPlayed={setHomeIntroPlayed}
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
      location={location}
    />
    <div className={location?.pathname !== '/' && styles.content}>{children}</div>
    <Footer />
  </>
)}

export default Layout;
