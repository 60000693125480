import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Icon from "./icon";
import { cn, mapEdgesToNodes } from "../lib/helpers";
import { StaticQuery, graphql } from "gatsby";
import { motion } from "framer-motion";
import * as styles from "./header.module.css";

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const item = {
  visible: { visibility: "visible" },
  hidden: { visibility: "hidden" },
};

const Header = ({
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  homeIntroPlayed,
  setHomeIntroPlayed,
  location,
}) => {
  const [animatedLogo, setAnimatedLogo] = useState(false);
  const [isHome, setIsHome] = useState(null);
  const [logoWidth, setLogoWidth] = useState(null);
  const [logoHeight, setLogoHeight] = useState(null);
  const [scrolling, setScrolling] = useState(null);
  const [logoWidthOffset, setLogoWidthOffset] = useState(null);

  useEffect(() => {
    if (location?.pathname === "/") {
      // console.log("set home intro played to true");
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, []);

  

  const onScroll = () => {
    // console.log(window.scrollY);

    // if (window.scrollY < window.innerHeight / 2 - 40) {
    //   let w =
    //     (window.innerHeight / 2 - window.scrollY) * 9.6 < window.innerWidth
    //       ? (window.innerHeight / 2 - window.scrollY) * 9.6
    //       : window.innerWidth;
    //   setLogoHeight(window.innerHeight / 2 - window.scrollY);
    //   setLogoWidth(w);
    // } else {
    // setLogoHeight(40);
    // setLogoWidth(384);
    // }

    let targWidth = window.innerWidth > 1024 ? 384 : 288;
    let wOffset = (window.innerWidth * .2);

    // setLogoWidthOffset()

    let h =
      Math.floor((window.innerHeight - 40.41) / 2 - window.scrollY) >= 40.41
        ? Math.floor((window.innerHeight - 40.41) / 2 - window.scrollY)
        : 40.41;
    let w =
      Math.floor(window.innerWidth -
        wOffset -
        window.scrollY * ((window.innerWidth / (window.innerHeight - 40.41)) * 2)) >
        targWidth
        ? Math.floor(window.innerWidth -
          wOffset -
          window.scrollY * ((window.innerWidth / (window.innerHeight - 38)) * 2))
        : targWidth;
    setLogoHeight(h);
    setLogoWidth(w);

    if (window.scrollY < window.innerHeight / 4 - 40.41) {
      setScrolling(false);
    } else {
      setScrolling(true);
    }
  };

  useEffect(() => {
    if (typeof window) {
      window.addEventListener("scroll", onScroll);
      window.addEventListener("resize", onScroll);
      onScroll();
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onScroll);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          projects: allSanityProjects {
            edges {
              node {
                projects {
                  _key
                  title
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const projects = data.projects ? mapEdgesToNodes(data.projects) : [];
        const projectNodes = projects && projects[0] && projects[0].projects;

        const testClick = (event, pathname) => {
          if (location.pathname.includes(pathname)) {
            event.preventDefault();
            onHideNav();
          }
        };

        const testHome = (event) => {
          if (location.pathname === "/") {
            event.preventDefault();
            if (typeof window) {
              window.scrollTo(0, 0);
            }
          }
        };

        useEffect(()=>{
          if(typeof window) {
          if(showNav) {
            document.body.classList.add('mobile-menu-shown');
          } else {
            document.body.classList.remove('mobile-menu-shown');

          }
        }
        }, [showNav]);
        return (
          <>
            <div className={styles.root}>
              <div className={styles.wrapper}>
                <div className={styles.mobileBranding}>
                  <Link onClick={(e) => testHome(e)} to="/">
                    <Icon symbol="logo" />
                  </Link>
                </div>

                <button className={styles.toggleNavButton} onClick={onShowNav}>
                  <Icon symbol="hamburger" />
                </button>

                <nav className={cn(styles.nav, showNav && styles.showNav)}>
                  <ul>
                    <li>
                      <Link to="/projects/" activeClassName={styles.active}>
                        Projects
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/" activeClassName={styles.active}>
                        Products
                      </Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="/studio/" activeClassName={styles.active}>
                        Studio
                      </Link>
                    </li>
                    <li>
                      <Link to="/press/" activeClassName={styles.active}>
                        Press
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className={cn(styles.mobileNav, showNav && styles.mobileNavShown)}>
              <button onClick={onHideNav} className={styles.close} type="button" role="Close Menu">
                <Icon symbol="close" />
              </button>
              <nav className={styles.mobileNavContainer}>
                <motion.ul
                  initial="hidden"
                  animate={showNav ? "visible" : "hidden"}
                  variants={list}
                >
                  <motion.li variants={item}>
                    <Link
                      onClick={(e) => testClick(e, "projects")}
                      to="/projects/"
                      activeClassName={styles.active}
                    >
                      Projects
                    </Link>
                  </motion.li>
                  <motion.li variants={item}>
                    <Link
                      onClick={(e) => testClick(e, "products")}
                      to="/products/"
                      activeClassName={styles.active}
                    >
                      Products
                    </Link>
                  </motion.li>
                  <motion.li variants={item}>
                    <Link
                      onClick={(e) => testClick(e, "studio")}
                      to="/studio/"
                      activeClassName={styles.active}
                    >
                      Studio
                    </Link>
                  </motion.li>
                  <motion.li variants={item}>
                    <Link
                      onClick={(e) => testClick(e, "press")}
                      to="/press/"
                      activeClassName={styles.active}
                    >
                      Press
                    </Link>
                  </motion.li>
                </motion.ul>
                <div className={styles.mobileNavFooter}>
                  <p>
                    Billy Cotton
                    <br />
                    <address className="not-italic">
                      508 West 26th Street, Suite 6E
                      <br />
                      New York, NY 10001
                    </address>
                  </p>
                  <br />
                  <a href="tel:7188582263" title="Phone">
                    718&nbsp;858&nbsp;2263
                  </a>
                  <br />
                  <br />
                  <a href="mailto:studio@billycotton.com" title="Email">
                    studio@billycotton.com
                  </a>
                  <br />
                  <br />
                  <a
                    href="https://www.instagram.com/billycotton/?hl=en"
                    target="_blank"
                    title="Instagram"
                  >
                    Instagram
                  </a>
                </div>
              </nav>
            </div>
            {isHome !== null && (
              <>
                {!isHome ? (
                  <div className={styles.branding}>
                    <Link to="/">
                      <Icon symbol="logo" />
                    </Link>
                  </div>
                ) : (
                  <motion.div
                    className={styles.homeBranding}
                    style={{
                      height: `${logoHeight}px`,
                      width: `${logoWidth}px`,
                      willChange: "width, height",
                    }}
                  >
                    {logoHeight > 1 && (
                      <div
                        className={cn(
                          styles.homeBrandingContainer
                          // scrolling && styles.homeBrandingContainerScrolling
                        )}
                      >
                        <Icon symbol="logo" />
                      </div>
                    )}
                  </motion.div>
                )}
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default Header;
